import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";  
import TablePagination from "@mui/material/TablePagination";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import { useLocation } from "react-router-dom";
const Logs = () => {
  const location = useLocation();
  const [logsData, setLogsData] = useState(null);
  const [filteredLogs, setFilteredLogs] = useState(null); // State for filtered data
  const [phoneNumberOptions, setPhoneNumberOptions] = useState([]);
  const [callTypeOptions, setCallTypeOptions] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
    phoneNumber: "",
    callType: "",
    startDate: "",
    endDate: ""

  });
  const [sortOrder, setSortOrder] = useState("desc");
  useEffect(() => {
    const apiUrl = `https://caltag-bf45c-default-rtdb.asia-southeast1.firebasedatabase.app/callLogs/${location.state}.json`; // Replace with your API endpoint
     // Fetch user data when the component mounts
    const response = axios
      .get(apiUrl)
      .then((response) => {
        // Update the state with the user data
          // setLogsData(response.data);
        const sortedData = Object.values(response.data).sort(
          (a, b) =>
            new Date(b.callTime).getTime() - new Date(a.callTime).getTime()
        );
        const uniquePhoneNumbers = getUniqueValues("phoneNumber");
        setPhoneNumberOptions(uniquePhoneNumbers);

        const uniqueCallTypes = getUniqueValues("callType");
        setCallTypeOptions(uniqueCallTypes);

        setLogsData(sortedData);
        setFilteredLogs(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching users data:", error);
      });
  }, []);

  const getUniqueValues = (key) => {
    if (logsData && logsData.length > 0) {
      const uniqueValues = Array.from(
        new Set(logsData.map((call) => call[key]))
      ).filter((value) => value !== undefined && value !== null);
      return uniqueValues;
    }
  };
  //adding filters//

  const handleFilterChange = (filterName, value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
    if (filterName === "name") {
      const filteredPhoneNumbers = logsData
        .filter((call) => call.name === value)
        .map((call) => call.phoneNumber);
      setPhoneNumberOptions(filteredPhoneNumbers);
    }
  
    // Update call type options based on the entire dataset
    if (filterName === "phoneNumber") {
      const filteredCallTypes = logsData
        .filter((call) => call.phoneNumber === value)
        .map((call) => call.callType);
      setCallTypeOptions(filteredCallTypes);
    }
  };

  const applyFilters = () => {
    const filteredData = logsData.filter((call) => {
      return (
        (filters.name === "" || call.name === filters.name) &&
        (filters.phoneNumber === "" ||
          call.phoneNumber === filters.phoneNumber) &&
        (filters.callType === "" || call.callType === filters.callType)&&
        (!filters.startDate || new Date(call.callTime) >= new Date(filters.startDate)) &&
        (!filters.endDate || new Date(call.callTime) <= new Date(filters.endDate + "T23:59:59")) 
      );
    });

    setFilteredLogs(filteredData);
  };
  

  const clearFilters = () => {
    setFilters({
      name: "",
      phoneNumber: "",
      callType: "",
      startDate: "",
      endDate: ""

    });
    setFilteredLogs(logsData); // Reset filtered data to the original data
  };

  //filters//

  const exportToXLSX = () => {
    // Create a new array without the "id" key
    const dataWithoutId = filteredLogs.map(
      ({ id, ...rest }) => rest
    );

    const worksheet = XLSX.utils.json_to_sheet(dataWithoutId);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "logsData");
    XLSX.writeFile(workbook, "logsData.xlsx");
  };
  const styles = {
    tableContainer: {
      maxWidth: "1200px",
      margin: "0 auto",
    },
    tableHeader: {
      background: "azure",
    },
    tableHeaderCell: {
      fontWeight: "bold",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f2f2f2",
      },
    },
    tableCell: {
      fontSize: "16px",
    },
  };

  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(9);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  console.log("filteredLogs", filteredLogs);
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + "./ORGB.jpg" + ")",
          height: "100vh",
          width: "100vw",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            borderBottom: "2px solid",
            padding: 10,
            fontSize: 26,
            fontWeight: 600,
            color: "black",
          }}
        >
          CALL LOGS
        </div>
        <div style={{ marginTop: 20 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 20,
              paddingBottom: 1,
              paddingLeft:20,
            }}
          >
            <div style={{ textAlign: "start" }}>
              <select
                style={{
                  padding: "8px", // Add padding
                  marginRight: "10px", // Add margin for spacing between dropdowns
                  fontSize: "10px", // Adjust font size
                  border: "1px solid #ccc", // Add a border
                  borderRadius: "4px",
                  cursor:"pointer" // Add border-radius for a rounded look
                }}
                value={filters.name}
                onChange={(e) => handleFilterChange("name", e.target.value)}
                onClick={applyFilters}
              >
                <option value="">Select Name</option>
                {getUniqueValues("name")?.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </select>

              <select
                style={{
                  padding: "8px",
                  marginRight: "10px",
                  fontSize: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  cursor:"pointer"
                }}
                value={filters.phoneNumber}
                onChange={(e) =>
                  handleFilterChange("phoneNumber", e.target.value)
                }
                onClick={applyFilters}
              >
                <option value="">Select Phone Number</option>
                {getUniqueValues("phoneNumber")?.map((phoneNumber, index) => (
                  <option key={index} value={phoneNumber}>
                    {phoneNumber}
                  </option>
                ))}
              </select>

              <select
                style={{
                  padding: "8px",
                  fontSize: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  cursor:"pointer"
                }}
                value={filters.callType}
                onChange={(e) => handleFilterChange("callType", e.target.value)}
                onClick={applyFilters}
                
              >
                <option value="">Select Call Type</option>
                {getUniqueValues("callType")?.map((callType, index) => (
                  <option key={index} value={callType}>
                    {callType}
                  </option>
                ))}
              </select>

              {/* Add start date filter */}
          <input
            type="date"
            value={filters.startDate}
            onChange={(e) => handleFilterChange("startDate", e.target.value)}
            style={{
              padding: "8px",
              fontSize: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginLeft:"2px",
              cursor:"pointer"
            }}
            onClick={applyFilters}
          />

          {/* Add end date filter */}
          <input
            type="date"
            value={filters.endDate}
            onChange={(e) => handleFilterChange("endDate", e.target.value)}
            style={{
              padding: "8px",
              fontSize: "9px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginLeft:"2px",
              cursor:"pointer"
            }}
            onClick={applyFilters}
          />

              {/* <Button
                style={{
                  height: 30,
                  width: 70,
                  background: "blue",
                  color: "white",
                  marginLeft: 10,
                }}
                onClick={applyFilters}
              >
                Apply
              </Button> */}
              <Button
                style={{
                  height: 30,
                  width: 70,
                  background: "red",
                  color: "white",
                  marginLeft: 10,
                }}
                onClick={clearFilters}
              >
                Clear
              </Button>
            </div>

            <div style={{ display: "flex", alignItems: "center"}}>
              <Button
                style={{
                  height: 30,
                  width: 150,
                  background: "green",
                  color: "white",
                  marginLeft: 10,
                }}
                onClick={exportToXLSX}
              >
                Export
              </Button>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[8, 100]}
            component="div"
            count={
              filteredLogs && filteredLogs.length
                ? filteredLogs.length
                : 50
            }
            rowsPerPage={rpg}
            page={pg}
            style={{ color: "black", fontWeight: 600 }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <div
          style={{
            overflow: "auto", // Add overflow property for scrolling
            height: "calc(85vh - 100px)", // Adjust the height as needed
          }}
        >
          <TableContainer component={Paper} style={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow style={styles.tableHeader}>
                  <TableCell style={styles.tableHeaderCell}>Name</TableCell>
                  <TableCell style={styles.tableHeaderCell}>
                    Phone Number
                  </TableCell>
                  <TableCell style={styles.tableHeaderCell}>
                    Call Time
                  </TableCell>
                  <TableCell style={styles.tableHeaderCell}>
                    Call Type
                  </TableCell>
                  <TableCell style={styles.tableHeaderCell}>
                    Call Duration
                  </TableCell>
                </TableRow>
              </TableHead>
              {filteredLogs &&
                filteredLogs.length > 0 &&
                filteredLogs
                  .slice(pg * rpg, pg * rpg + rpg)
                  .map((call, index) => (
                    <TableRow key={index}>
                      <TableCell style={styles.tableCell}>
                        {call.name}
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        {call.phoneNumber}
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        {call.callTime}
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        {call.callType}
                      </TableCell>
                      <TableCell style={styles.tableCell}>
                        {call.callDuration} seconds
                      </TableCell>
                    </TableRow>
                  ))}
            </Table>
          </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logs;
