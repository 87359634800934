import { initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyC3p8PLBFEWUAiZlEzNKJv7XR3VOwQhgW8",
  authDomain: "caltag-6f842.firebaseapp.com",
  databaseURL: "https://caltag-6f842-default-rtdb.firebaseio.com",
  projectId: "caltag-6f842",
  storageBucket: "caltag-6f842.appspot.com",
  messagingSenderId: "994682900024",
  appId: "1:994682900024:web:f1e6d98413e4fcc080e0cd",
  measurementId: "G-3VPEJRXY18"
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)

 