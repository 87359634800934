import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";


const Leads = () => {
  const location = useLocation();
  const [leadsData, setLeadsData] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');
  console.log("leadsData", leadsData);
  const apiUrl = `https://caltag-bf45c-default-rtdb.asia-southeast1.firebasedatabase.app/Leads/${location.state}.json`; // Replace with your API endpoint

  useEffect(() => {
    // Fetch user data when the component mounts
    const response = axios
      .get(apiUrl)
      .then((response) => {
        // Update the state with the user data
        const data = response.data;
        setLeadsData(response.data);
        const uniqueNames = [...new Set(Object.values(data).map((user) => user.name))];
        const uniquePhones = [...new Set(Object.values(data).map((user) => user.phoneNumber))];
        setNameOptions(uniqueNames);
        setPhoneOptions(uniquePhones);
      })
      .catch((error) => {
        console.error("Error fetching user data:",+error);
      });
  }, []);


const [nameOptions, setNameOptions] = useState([]);
const [phoneOptions, setPhoneOptions] = useState([]);

const handleNameFilterChange = (event) => {
  const selectedName = event.target.value;
  setNameFilter(selectedName);

  const filteredPhones = Object.values(leadsData)
      .filter((user) => user.name === selectedName)
      .map((user) => user.phoneNumber);
    setPhoneOptions(filteredPhones);
};


const handlePhoneFilterChange = (event) => {
  setPhoneFilter(event.target.value);
};
const filteredLeadsData = leadsData
  ? Object.values(leadsData).filter((user) => {
      return (
        user.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
        user.phoneNumber.includes(phoneFilter)
        
      );
      
    })
    
  : [];



  const exportToXLSX = () => {
    const visibleColumns = [
      { label: "Name", key: "name" },
      { label: "Phone", key: "phoneNumber" },
      // Add more columns as needed
    ];
  
    const data = filteredLeadsData
    .filter((user) => Object.values(user).some((value) => value !== ''))
    .map((user) => {
      const rowData = {};
      visibleColumns.forEach((column) => {
        rowData[column.label] = user[column.key];
      });
      return rowData;
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "LeadsData");
    XLSX.writeFile(workbook, "leadsData.xlsx");
  };

  const styles = {
    tableContainer: {
      maxWidth: "1000px",
      margin: "0 auto",
    },
    tableHeader: {
      background: "azure",
    },
    tableHeaderCell: {
      fontWeight: "bold",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f2f2f2",
      },
    },
    tableCell: {
      fontSize: "12px",
    },
  };
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(9);
  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  
  const applyFilter = () => {
    // You can update the filter logic here if needed
  };

  const clearFilter = () => {
    setNameFilter('');
    setPhoneFilter('');
  };
  return (
    <div
      style={{
        backgroundImage: "url(" + "./ORGB.jpg" + ")",
        height: "98vh",
        width: "98vw",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          borderBottom: "2px solid",
          padding: 10,
          fontSize: 26,
          fontWeight: 600,
          color:"black"
        }}
      >
        Leads
      </div>

      <div style={{ marginTop: 10 }}>
        <div style={{ marginBottom: 1,display: "flex" ,justifyContent: 'space-between',paddingRight: 20,paddingLeft:50
              }}>
          <div>
              <select value={nameFilter} onChange={handleNameFilterChange} style={{ padding: '5px', borderRadius: '8px', border: '1px solid #ccc' ,width:"260px",height:"5vh",cursor:"pointer"}}>
                <option value="">NAME</option>
                {nameOptions.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </select>
              <select value={phoneFilter} onChange={handlePhoneFilterChange}style={{ padding: '5px', borderRadius: '8px', border: '1px solid #ccc',width:"260px",height:"5vh",marginLeft:10,cursor:"pointer" }}>
                <option value="">PHONE NUMBER</option>
                {phoneOptions.map((phone, index) => (
                  <option key={index} value={phone}>
                    {phone}
                  </option>
                ))}
                </select>
                <Button
                style={{ height: 32, width: 150, background: 'red', color: 'white',marginLeft:10,borderRadius: '8px', }}
                onClick={clearFilter}
                variant="contained"
                >
                  Clear Filter
                </Button>
            </div>  
                  <div style={{ display: "flex", alignItems: "center"}}>
                      <Button
                          style={{
                            height: 32,
                            width: 150,
                            background: "green",
                            color: "white",
                            marginLeft: 10,
                            borderRadius: '10px',
                          }}
                          onClick={exportToXLSX}
                          variant="contained"
                          >
                          Export
                      </Button>
                  </div>
         
          </div>
          <TablePagination
            rowsPerPageOptions={[8, 100]}
            component="div"
            count={
              filteredLeadsData && Object.values(filteredLeadsData).length
                ? Object.values(filteredLeadsData).length
                : 50
            }
            rowsPerPage={rpg}
            page={pg}
            style={{ color: "black", fontWeight: 600 }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <div
          style={{
            overflow: "auto", // Add overflow property for scrolling
            height: "calc(85vh - 100px)", // Adjust the height as needed
          }}
        >
        
          <TableContainer component={Paper} style={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow style={styles.tableHeader}>
                <TableCell style={styles.tableHeaderCell}>Name</TableCell>
                <TableCell style={styles.tableHeaderCell}>Phone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredLeadsData &&
            filteredLeadsData.slice(pg * rpg, pg * rpg + rpg).map((user, index) => (
                  <TableRow key={index} style={styles.tableRow}>
                    <TableCell style={styles.tableCell}>{user.name}</TableCell>
                    <TableCell style={styles.tableCell}>{user.phoneNumber}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
         </TableContainer>
         </div>
      </div>
    </div>
  );
};

export default Leads;
