import React, { useState, useEffect } from "react";
import "./Signupcss.css"; // Import your styles
import { TextField, Typography } from "@mui/material";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "./firebase";
import { useHistory } from "react-router-dom";
import SentOTP from "./SentOTP"; // Import the SentOTP component
const AuthForm = () => {
  const [isSignIn, setIsSignIn] = useState(true);
  const history = useHistory();
  const [phone, setPhone] = useState("+91");
  const [user, setuser] = useState(null);
  const [otp, setotp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(false);

   const startResendTimer = () => {
    setIsResendDisabled(true);
    setTimeout(() => {
      setIsResendDisabled(false);
    }, 60000); // Allow resending after 30 seconds
  };
  const sendotp = async () => {
    try {
      console.log(phone.target.value);
      const num=phone.target.value;
      const tempNumber = `+${num}`;
     
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      const confirmation = await signInWithPhoneNumber(
        auth,
        tempNumber,
        recaptcha
      );
      setuser(confirmation);
      setErrorMessage("");
      startResendTimer(); 
    } catch (err) {
      console.error(err);
      if (
        err == "FirebaseError: Firebase: TOO_SHORT (auth/invalid-phone-number)."
      ) {
        setErrorMessage("Invalid Phone Number");
      } else {
        setErrorMessage("Error sending OTP. Please try again." + err);
      }
    }
  };
  const verifyOtp = async () => {
    try {
      const data = await user.confirm(otp);
      console.log(data);
      if (data && data?._tokenResponse) {
        history.push({ pathname: "selectreport", state: `+${phone}` });
        sessionStorage.setItem("isLoggedin", true);
      }
    } catch (err) {
      console.error(err);
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };
  const resendOtp = () => {
    sendotp();
  };
  useEffect(() => {
    startResendTimer(); // Initialize the resend timer
  }, []);

  
  useEffect(() => {
    // Add 'sign-in' class after component mounts
    setIsSignIn(true);
  }, []);

  const toggle = () => {
    setIsSignIn(!isSignIn);
  };

  return (
    <div className={`container ${isSignIn ? "sign-in" : "sign-up"}`}>
      {/* FORM SECTION */}
      <div className="row">
        {/* SIGN UP */}
        <div className="col align-items-center flex-col sign-up">
           <div className="form-wrapper align-items-center">
          
          </div>
        </div>
        <div className="col align-items-center flex-col sign-in">
          <div className="form-wrapper align-items-center">
            <div className={`form ${isSignIn ? "sign-in" : "sign-up"}`}>
            <SentOTP />
            </div>
             
          </div>
          <div className="form-wrapper"></div>
        </div>
        {/* END SIGN IN */}
      </div>
      {/* END FORM SECTION */}
      {/* CONTENT SECTION */}
      <div className="row content-row">
        {/* SIGN IN CONTENT */}
        <div className="col align-items-center flex-col">
          <div className="text sign-in">
            <h2>CALTAG REPORT</h2>
          </div>
          <div className="img sign-in"></div>
        </div>
        {/* END SIGN IN CONTENT */}
        {/* SIGN UP CONTENT */}
        <div className="col align-items-center flex-col">
          <div className="img sign-up"></div>
          <div className="text sign-up">
            <h2>Join with us using Caltag</h2>
          </div>
        </div>
        {/* END SIGN UP CONTENT */}
      </div>
    </div>
  );
};

export default AuthForm;
