import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "./Phone.css";
import { TextField, Typography, Button } from "@mui/material";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "./firebase";
import { useHistory } from "react-router-dom";


function SentOTP() {
  const history = useHistory();
  const [phone, setPhone] = useState("+91");
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const startResendTimer = () => {
    setIsResendDisabled(true);
    setTimeout(() => {
      setIsResendDisabled(false);
    }, 30000); // Allow resending after 30 seconds
  };

  const sendOtp = async () => {
    try {
      const tempNumber = `+${phone}`;
      if (tempNumber.length < 10) {
        throw new Error("Invalid Phone Number");
      }
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      const confirmation = await signInWithPhoneNumber(auth, tempNumber, recaptcha);
      setUser(confirmation);
      setErrorMessage("");
      startResendTimer();
    } catch (err) {
      console.error(err);
      if (err.code === "auth/invalid-phone-number" || err.code === "auth/argument-error") {
        setErrorMessage("Invalid Phone Number");
      } else {
        setErrorMessage("Error: " + err.message);
      }
    }
  };

  const verifyOtp = async () => {
    try {
      const data = await user.confirm(otp);
      console.log(data);
      if (data && data?._tokenResponse) {
        history.push({ pathname: "selectreport", state: `+${phone}` });
        sessionStorage.setItem("isLoggedin", true);
      }
    } catch (err) {
      console.error(err);
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };

  const resendOtp = async () => {
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      const confirmation = await signInWithPhoneNumber(auth, `+${phone}`, recaptcha);
      setUser(confirmation); // Update user state when resending OTP
      setErrorMessage("");
      startResendTimer();
    } catch (err) {
      console.error(err);
      setErrorMessage("Error resending OTP. Please try again."+err);
    }
  };
  

  useEffect(() => {
    startResendTimer();
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh" }}>
      <div style={{ width: "90%", maxWidth: "400px" }}>
        
        <div style={{ marginBottom: "20px", fontSize: "30px", fontWeight: "600" }}>LOGIN</div>
        <PhoneInput
          country={"in"}
            type="text"
            defaultValue={phone}
            onChange={(value) => setPhone(value)}
            inputStyle={{ width: "100%", maxWidth: "400px" }} // Adjusted width and max-width
            containerStyle={{ marginBottom: "20px" }}
        />

        <Button
          variant="contained"
          style={{ marginBottom: "20px" , width: "40%",  fontSize:".9em"}} // Adjusted width and max-width
          onClick={sendOtp}
        >
          Send OTP
        </Button>
        {errorMessage && (
          <Typography variant="caption" color="error" style={{ marginTop: "10px" }}>
            {errorMessage}
          </Typography>
        )}
        <div id="recaptcha"></div>
        <TextField
          variant="outlined"
          label="Enter OTP"
          size="small"
          style={{ marginBottom: "20px", width: "100%" }}
          onChange={(e) => setOtp(e.target.value)}
        />
        
        <Button
          variant="contained"
          style={{ background: "green", color: "white", width: "54%" , fontSize:".9em"}}
          onClick={verifyOtp}
        >
          Verify OTP
        </Button>
        <p
         
          style={{ marginBottom: "20px" ,  color:"blue" ,fontSize:".9em", cursor: "pointer"}}
          onClick={resendOtp}
          disabled={isResendDisabled}
        >
          Resend OTP ?
        </p>
      </div>
    </div>
  );
}
export default SentOTP;