import React from 'react'
import { Button } from "@mui/material";
function Select() {
  return (
    <div
    style={{
      height: "99vh",
      width: "99vw",
      border: "2px solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundImage: "url(" + "./Callbackground.jpg" + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}
  >
 
          {/* <marquee width="40%" direction="right" height="30%">Get Your All  Call Logs Here    </marquee> */}
    <div>
    <Button variant="contained"
     style={{
            height: 50,
            width: 250,
            background: "coral",
            color: "white",
            
          }}
        >
          Organization Report 
        </Button>
        <Button variant="contained"
     style={{
            height: 50,
            width: 250,
            background: "coral",
            color: "white",
            
          }}
        >
          Individual Report 
        </Button>
    
    </div>
    
    </div>
  )
}

export default Select