import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import { useLocation } from "react-router-dom";

const PrivateNumber = () => {
  const location = useLocation();
  const [privateNumberData, setPrivateNumberData] = useState(null);
  console.log("privateNumberData", privateNumberData);
  const apiUrl =
    "https://testproject-9a6e7-default-rtdb.firebaseio.com/privateNumbers/${location.state}.json";

  useEffect(() => {
    // Fetch user data when the component mounts
    const response = axios
      .get(apiUrl)
      .then((response) => {
        // Update the state with the user data
        console.log("response", response);
        setPrivateNumberData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const exportToXLSX = () => {
    const data = Object.values(privateNumberData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "LeadsData");
    XLSX.writeFile(workbook, "privateNumberData.xlsx");
  };
  const styles = {
    tableContainer: {
      maxWidth: "1000px",
      margin: "0 auto",
    },
    tableHeader: {
      background: "azure",
    },
    tableHeaderCell: {
      fontWeight: "bold",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f2f2f2",
      },
    },
    tableCell: {
      fontSize: "16px",
    },
  };
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + "./BackG1.jpg" + ")",
          height: "98vh",
          width: "98vw",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            borderBottom: "2px solid",
            padding: 10,
            fontSize: 26,
            fontWeight: 600,
            color: "white",
          }}
        >
          PrivateNumbers
        </div>

        <div style={{ marginTop: 20 }}>
          <div
            style={{ textAlign: "end", paddingRight: 20, paddingBottom: 20 }}
          >
            <Button
              style={{
                height: 30,
                width: 150,
                background: "green",
                color: "white",
              }}
              onClick={exportToXLSX}
            >
              Export
            </Button>
          </div>
          <TableContainer component={Paper} style={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow style={styles.tableHeader}>
                  <TableCell style={styles.tableHeaderCell}>
                    Phone Number
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {privateNumberData &&
                  Object.entries(privateNumberData).map(
                    ([phoneNumber, value], index) => (
                      <TableRow key={index} style={styles.tableRow}>
                        <TableCell style={styles.tableCell}>
                          {phoneNumber}
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default PrivateNumber;
