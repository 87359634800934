import React from "react";
import PhoneInput from "react-phone-input-2";
import { Input, Modal, TextField } from "@mui/material";
import { useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "./firebase";
import { useHistory } from "react-router-dom";
import { Button,IconButton} from "@mui/material";
import axios from "axios";

const AddNumberModal = ({ open, orgId, handleClose, fetchOrgDetails }) => {
  console.log("orgId", orgId);
  const history = useHistory();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("+91");
  const [user, setuser] = useState(null);
  const [otp, setotp] = useState("");

  const sendotp = async () => {
    try {
      const tempNumber = `+${phone}`;
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      const confirmation = await signInWithPhoneNumber(
        auth,
        tempNumber,
        recaptcha
      );
      setuser(confirmation);
    } catch (err) {
      console.error(err);
    }
  };

  const verifyOtp = async () => {
    try {
      const data = await user.confirm(otp);
      // console.log(data);
      // history.push({ pathname: "selectreport", state: `+${phone}` });
      handleAddPhoneNumber();
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddPhoneNumber = async () => {
    let apiUrl = `https://caltag-6f842-default-rtdb.firebaseio.com/organization/${orgId}.json`;
    console.log(apiUrl);

    const tempNumber = `+${phone}`;
    try {
      const formData = {
        Name: name,
        Phone: tempNumber,
      };
      const response = await axios.post(
        `https://caltag-6f842-default-rtdb.firebaseio.com/organization/${orgId}.json`,
        formData
      );
      handleClose();
      fetchOrgDetails();
      setName("");
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: "60vh",
            width: "30vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            background: "azure",
            borderRadius: 10,
            textAlign: "center",
            marginTop:120
          }}
        >
          <div
            style={{
              borderRadius: 8,
              border: "2px solid",
              textAlign: "center",
              width: 50,
              height: 25,
              marginLeft: 340,
              marginBottom: 10,
              background: "azure",
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                textAlign: "left",
                fontSize: 9,
                color: "black",
                padding: 0, // Set padding to 0
                minWidth: 0, // Set minWidth to 0
              }}
            >
              Close
            </Button>
          </div>
         
          <div>
            <label>Enter name</label>
            <input
              type="text"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: "100%", height: 30 }}
            />
            <br />
            <br />
            <label>Enter number</label> 
            <PhoneInput
              country={"in"}
              type="text"
              defaultValue={phone}
              onChange={(value) => setPhone(value)}
              style={{ display: "flex" }}
            ></PhoneInput>
            <br />
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                style={{
                  height: 30,
                  width: 150,
                  background: "blue",
                  color: "white",
                }}
                onClick={sendotp}
                // onClick={handleAddPhoneNumber}
              >
                Send OTP
              </Button>
            </div>
            <br/> <br/>
          </div>
          <div id="recaptcha"></div> <br />
          <TextField
            variant="outlined"
            label="Enter OTP"
            size="small"
            onChange={(e) => setotp(e.target.value)}
          ></TextField>
          <br />
          <Button
            variant="contained"
            style={{
              height: 30,
              width: 150,
              background: "green",
              color: "white",
            }}
            onClick={verifyOtp}
          >
            Verify OTP
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNumberModal;
