import {
  Alert,
  Button,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddNumberModal from "./AddNumberModal";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const Organization = () => {
  const location = useLocation();
  const history = useHistory();

  const [openModal, setModalOpen] = useState(false);
  const [orgData, setOrgData] = useState([]);
  const [orgId, setOrgId] = useState("");

  const fetchOrgDetails = async () => {
    setOrgId(location?.state);

    let response = await axios.get(
      `https://caltag-6f842-default-rtdb.firebaseio.com/organization/${location.state}.json`
    );
    console.log("response", response);
    if (response.data) {
      setOrgData(response.data);
    } else {
      setOrgData([]);
    }
  };
  useEffect(() => {
    fetchOrgDetails();
  }, []);

  const styles = {
    tableContainer: {
      maxWidth: "1000px",
      margin: "0 auto",
    },
    tableHeader: {
      background: "azure",
    },
    tableHeaderCell: {
      fontWeight: "bold",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f2f2f2",
      },
    },
    tableCell: {
      fontSize: "16px",
    },
  };

  const [deleteRowId, setDeleteRowId] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  console.log("deleteRowId", deleteRowId);
  // const handleDelete = async (item) => {
  //   console.log(item, "item");
  //   let response = await axios.delete(
  //     `https://testproject-9a6e7-default-rtdb.firebaseio.com/organization/${location.state}/${item}.json`
  //   );
  //   fetchOrgDetails();
  // };

//delete//

const handleDelete = async (item) => {
  setDeleteRowId(item);
  setConfirmDelete(true);
};

const handleConfirmDelete = async () => {
  try {
    let response = await axios.delete(
      `https://caltag-6f842-default-rtdb.firebaseio.com/organization/${location.state}/${deleteRowId}.json`
    );
    fetchOrgDetails();
    setConfirmDelete(false);
  } catch (error) {
    console.error("Error deleting item:", error);
    // Handle error, show error message, etc.
  }
};

const handleCancelDelete = () => {
  setConfirmDelete(false);
};


//delete//
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(9);
  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  return (
    <>
     <Snackbar
        open={confirmDelete}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleCancelDelete}
      >
        <Alert
          severity="warning"
          variant="filled"
          onClose={handleCancelDelete}
        >
          Are you sure you want to delete this item?
          <Button onClick={handleConfirmDelete} color="inherit" size="small">
            Yes
          </Button>
          <Button onClick={handleCancelDelete} color="inherit" size="small">
            No
          </Button>
        </Alert>
      </Snackbar>
      <div
        style={{
          height: "98vh",
          width: "98vw",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: "url(" + "./ORGB.jpg" + ")",
          // background: "rgb(2,0,36)",
          // background:
          //   "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(32,35,62,1) 0%, rgba(143,166,160,1) 0%)",
        }}
      >
        <div
          style={{
            borderBottom: "2px solid",
            padding: 10,
            fontSize: 26,
            fontWeight: 600,
            color: "Black",
          }}
        >
           Organization Report
        </div>

        <div style={{ marginTop: 20 }}>
          <div style={styles.tableContainer}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <div style={{ fontSize: 28,color:"#185fa4" }}></div>
              <div
                style={{
                  textAlign: "end",
                }}
              >
                <Button
                  style={{
                    height: 30,
                    width: 250,
                    background: "green",
                    color: "white",
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  Add phone number
                </Button>
                <AddNumberModal
                  open={openModal}
                  orgId={orgId}
                  handleClose={() => setModalOpen(false)}
                  fetchOrgDetails={fetchOrgDetails}
                />
              </div>
            </div>
            <TableContainer component={Paper} style={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow style={styles.tableHeader}>
                    <TableCell style={styles.tableHeaderCell}>
                      Name  
                    </TableCell>
                    <TableCell style={styles.tableHeaderCell}>
                      Phone Number
                    </TableCell>
                    <TableCell style={styles.tableHeaderCell}>Logs</TableCell>
                    <TableCell style={styles.tableHeaderCell}>Leads</TableCell>
                    <TableCell style={styles.tableHeaderCell}>Tasks</TableCell>
                    <TableCell style={styles.tableHeaderCell}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orgData &&
                    Object.keys(orgData)
                      ?.slice(pg * rpg, pg * rpg + rpg)
                      ?.map((item) => (
                        <TableRow key={orgData[item].Name}>
                          <TableCell>{orgData[item].Name}</TableCell>
                          <TableCell>{orgData[item].Phone}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() =>
                                history.push({
                                  pathname: "logs",
                                  state: orgData[item].Phone,
                                })
                              }
                            >
                              Logs
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() =>
                                history.push({
                                  pathname: "leads",
                                  state: orgData[item].Phone,
                                })
                              }
                            >
                              Leads
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() =>
                                history.push({
                                  pathname: "tasks",
                                  state: orgData[item].Phone,
                                })
                              }
                            >
                              Tasks
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button onClick={() => handleDelete(item)}>
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
                <TablePagination
                  rowsPerPageOptions={[8, 10]}
                  component="div"
                  count={
                    orgData && Object.keys(orgData).length
                      ? Object.keys(orgData).length
                      : 50
                  }
                  rowsPerPage={rpg}
                  page={pg}
                  style={{ fontWeight: 600 }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organization;
