import { Card } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Category = () => {
  const history = useHistory();
  const location = useLocation();
  console.log(location);

  const cardstyle = {
    height: 150,
    width: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    fontSize: 20,
    fontWeight: 600,
    // color:"black",
    background:"azure",
    // backgroundImage: "url(" + "./Background.jpg" + ")",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div
      style={{
        height: "99vh",
        width: "99vw",
        border: "2px solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "url(" + "./BackG1.jpg" + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          width: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Card
          style={cardstyle}
          onClick={() =>
            history.push({ pathname: "leads", state: location.state })
          }
        >
          Leads
        </Card>
        <Card
          style={cardstyle}
          onClick={() =>
            console.log(location.state)
            // history.push({ pathname: "logs", state: location.state })
          }
        >
          Logs
        </Card>
        {/* <Card
          style={cardstyle}
          onClick={() =>
            history.push({ pathname: "privatenumbers", state: location.state })
          }
        >
          Private Number
        </Card> */}
      </div>
    </div>
  );
};

export default Category;
