import { Card } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const SelectReportType = () => {
  const history = useHistory();
  const location = useLocation();
  console.log(location);

  const cardstyle = {
    height: 150,
    width: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    fontSize: 20,
    fontWeight: 600,
    // color: "white",
    // backgroundImage: "url(" + "./Background.jpg" + ")",
    background: "azure",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div
      style={{
        height: "99vh",
        width: "99vw",
        border: "2px solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // backgroundImage: "url(" + "./Background.jpg" + ")",
        background: "rgb(2,0,36)",
        background:
          "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(143,166,160,1) 100%)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          width: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Card
          style={cardstyle}
          onClick={() =>
            history.push({ pathname: "category", state: location.state })
          }
        >
          Individual Report
        </Card>
        <Card
          style={cardstyle}
          onClick={() =>
            history.push({ pathname: "organization", state: location.state })
          }
        >
          Organization Report
        </Card>
      </div>
    </div>
  );
};

export default SelectReportType;
