import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SentOTP from "./components/SentOTP";
import Category from "./components/Category";
import Leads from "./components/Leads";
import Logs from "./components/Logs";
import PrivateNumber from "./components/PrivateNumber";
import Select from "./components/select";
import SelectReportType from "./components/SelectReportType";
import Organization from "./components/Organization";
import ProtectedRoute from "./ProtectedRoute";
import Tasks from "./components/Tasks";
import AuthForm from "./components/AuthForm";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          {/* <Route exact path="/sentotp" component={SentOTP} /> */}

          {/* Without login */}
          <Route exact path="/" component={AuthForm} />
          {/* <Route exact path="/" component={SentOTP} /> */}
          {/* With login */}
          <Route path="/category" component={Category} />
          <Route path="/selectreport" component={SelectReportType} />
          <Route path="/organization" component={Organization} />
          <Route path="/leads" component={Leads} />
          <Route path="/logs" component={Logs} />
          <Route path="/tasks" component={Tasks} />
          <Route path="/privatenumbers" component={PrivateNumber} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
